<template>
  <v-row class="mx-0">
    <v-col cols="12" md="6">
      <v-row style="height: 100%">
        <v-col cols="12">
          <slot name="quotes-8" :visible="true"></slot>
        </v-col>
        <v-col cols="12">
          <slot name="journal-7" :visible="true">
            <v-skeleton-loader class="mb-6" type="heading"></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </slot>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row style="height: 100%;">
        <v-col cols="12" md="6">
          <slot name="text-5" :visible="true">
            <v-skeleton-loader class="my-6" type="heading"></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </slot>
        </v-col>
        <v-col cols="12" md="6">
          <slot
            name="text-4"
            :visible="$vuetify.breakpoint.smAndDown ? scrolled : true"
          >
            <v-skeleton-loader class="my-6" type="heading"></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </slot>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row style="height: 100%">
        <v-col cols="12" md="2">
          <slot
            name="text-2"
            :visible="$vuetify.breakpoint.smAndDown ? scrolled : true"
          >
            <v-skeleton-loader class="my-6" type="heading"></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </slot>
        </v-col>
        <v-col cols="12" md="7">
          <slot
            name="images-0"
            :visible="$vuetify.breakpoint.smAndDown ? scrolled : true"
          ></slot>
        </v-col>
        <v-col cols="12" md="3">
          <slot
            name="text-3"
            :visible="$vuetify.breakpoint.smAndDown ? scrolled : true"
          >
            <v-skeleton-loader class="my-6" type="heading"></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </slot>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-lazy
        v-model="visiblePrimary"
        style="height: 100%"
        :options="{
          threshold: 0.5
        }"
        transition="fade-transition"
      >
        <v-row style="height: 100%">
          <v-col cols="12" md="6">
            <slot name="text-1" :visible="visiblePrimary && scrolled"></slot>
          </v-col>
          <v-col cols="12" md="6">
            <slot name="text-6" :visible="visiblePrimary && scrolled"></slot>
          </v-col>
        </v-row>
      </v-lazy>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EditorTemplate4",
  data: () => ({
    visiblePrimary: false,
    visibleSecondary: false,
    scrolled: false
  }),
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = true;
    }
  }
};
</script>
